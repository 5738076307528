import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (    
    <nav className="fixed top-0 left-0 z-20 w-full bg-white border-b navbar dark:border-black md:max-h-32">
      <div className="flex flex-wrap items-center justify-between max-w-screen-xl p-4 mx-auto">
        <Link to="/" className="flex items-cen ter">
          <img src="https://www.samsung.com/etc.clientlibs/samsung/clientlibs/consumer/global/clientlib-common/resources/images/gnb-desktop-120x32.png" className="h-8 mr-3 md:h-8" alt="Flowbite Logo" />
        </Link> 
        <div className="flex md:order-2">
          <Link to="/login" className='hidden ml-32 md:block'>
            <button type="button" className="px-4 py-2 mr-3 text-sm font-medium text-center text-white bg-black rounded-lg ml-104 hover:bg-black focus:ring-4 focus:outline-none focus:ring-white md:mr-21 dark:bg-black dark:hover:bg-black dark:focus:ring-black">Log In</button>
          </Link>
          <Link to="/register" className="hidden mr-24 md:block">
            <button type="button" className="px-4 py-2 text-sm font-medium text-center text-white bg-black rounded-lg mr-9 hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 md:mr-14 dark:bg-black dark:hover:bg-black dark:focus:ring-black">Sign Up</button>
          </Link>
          <button
            type="button"
            onClick={toggleMobileMenu}
            className="inline-flex items-center justify-center w-10 h-10 p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-expanded={!isMobileMenuOpen}
          >
            {isMobileMenuOpen ? (
              <svg
                className="w-6 h-6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>
        </div>
        <div className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${isMobileMenuOpen ? 'block' : 'hidden'}`} id="navbar-sticky">
          <ul className="flex flex-col p-4 mt-4 ml-32 font-medium text-black bg-transparent md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-transparent dark:bg-transparent">
            <li>
              <Link to="/" className="block py-2 pl-3 pr-4 text-black bg-transparent hover:bg-gray-100 md:bg-transparent md:text-black md:pl-0 md:dark-text-black" aria-current="page">Home</Link>
            </li>
            <li>
              <Link to="/about"  className="block py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-black md:pl-0 md:dark-text-black">About</Link>
            </li>
            <li>
              <Link to="/service" className="block py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-black md:pl-0 md:dark-text-black">Services</Link>
            </li>
            <li>
              <Link to="/contact" className="block py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-black md:pl-0 md:dark-text-black">Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
