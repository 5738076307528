import React from 'react'
import Navbar from './Navbar'

export default function () {
  return (
    <>
        <Navbar/>
    </>
  )
}
