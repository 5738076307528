import React from 'react'
import Hero from './Hero'
import Product from './Product'

export default function () {
  return (
    <>
    <Hero/>
    <Product/>
    </>
  )
}
