import './App.scss';
import Routes from "./pages/Routes"

function App() {
  return (
    <>
        <Routes/>
    </>
  );
}

export default App;
