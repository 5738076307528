import React from 'react'
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import Home from './Home'
import Login from './Login/Login'
import Header from '../components//Header'
import Footer from '../components/Footer'
import About from "./About"
import Contact from './Contact'
import Register from './Register'
import NoPage from './NoPage'
import Service from './Services/Service'
import Services from './Services'
import Forgot from './Forgot'

export default function CustomRoutes () {
  return (
    <BrowserRouter>
       <Header/>
       <main>
       <Routes>
                   <Route path='/' element={<Home />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/service' element={<Services />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/register' element={<Register />} />
                    <Route path='/forgotpassword' element={<Forgot />} />
                    

            <Route path='*' element={<NoPage />} />
            
        </Routes>
       </main>
       <Footer/>
        
    </BrowserRouter>
  )
}
