import React from 'react'

export default function Hero() {
  return (
    <section className="bg-black">
    <div className="grid max-w-screen-xl px-4 py-8 mx-auto mt-10 lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight mt-14 md:text-5xl xl:text-6xl dark:text-white">Payments tool for software companies</h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">From checkout to global sales tax compliance, companies around the world use Flowbite to simplify their payment stack.</p>
            <a href="#" className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-red-950 focus:ring-4 focus:ring-bg-red-950 dark:focus:ring-red-950">
                Get started
                <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </a>
            <a href="#" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border rounded-lg border-bue-300 hover:bg-black focus:ring-4 focus:ring-blue-100 dark:text-white dark:border-white dark:hover:bg-red-950 dark:focus:ring-stone-950">
                Speak to Sales
            </a> 
        </div>
        <div className="hidden mt-5 mt-10 lg:mt-10 lg:col-span-5 lg:flex">
            <img src="https://images.samsung.com/is/image/samsung/assets/pk/smartphones/galaxy-s23-ultra/images/galaxy-s23-ultra-highlights-spen.jpg" alt="mockup"/>
        </div>                
    </div>
</section>

  )
}
